<template>
    <VJoyDialog ref="profileViewBlocker" pattern-brand class="profile-view-blocker" data-testid="profile-view-blocker" @dialog:confirm="hide">
        <template #dialog-header>
            <p class="profile-view-blocker__header">
                {{ $t('profile.view.blocker.header') }}
            </p>
        </template>
        <template #dialog-subheader>
            <p class="profile-view-blocker__subheader">
                {{ $t('profile.view.blocker.subheader') }}
            </p>
        </template>
        <template #dialog-body>
            <MaltImg width="324" height="235" src="/profile/features/mediation.svg" alt="" />
        </template>
        <template #dialog-confirm>
            <VJoyButton
                variant="main"
                size="medium"
                data-testid="profile-view-blocker-create-account"
                :label="$t('profile.view.blocker.create.account')"
                :href="signupUrl"
            />
        </template>
        <template #dialog-cancel>
            <VJoyButton
                variant="ghost"
                size="medium"
                data-testid="profile-view-blocker-signin"
                :label="$t('profile.view.blocker.signin')"
                :href="signinUrl"
            />
        </template>
    </VJoyDialog>
</template>

<script setup lang="ts">
    import {computed, ref, watch} from 'vue';
    import {MaltImg} from '#components';
    import {useRoute} from '@typed-router';
    import {VJoyDialog, VJoyButton} from '@maltjoy/core-vue';

    const profileViewBlocker = ref<InstanceType<typeof VJoyDialog>>();

    const route = useRoute();

    const props = defineProps<{
        open: boolean;
    }>();

    watch(
        () => props.open,
        () => {
            if (props.open) {
                profileViewBlocker.value?.show();
            }
        },
        {immediate: true},
    );

    function hide() {
        profileViewBlocker.value?.hide();
    }

    const currentPageUrl = `/profile${route.fullPath}`;

    const signupUrl = computed(() => {
        return `/who-are-you?redirect=${encodeURIComponent(currentPageUrl)}&pageSource=profile_page&componentSource=profile-view-blocker`;
    });

    const signinUrl = computed(() => {
        return `/signin?redirect=${encodeURIComponent(currentPageUrl)}&pageSource=profile_page&componentSource=profile-view-blocker`;
    });
</script>

<style scoped lang="scss">
    .profile-view-blocker {
        &__header {
            max-width: 90%;
            margin: auto;
        }

        &__subheader {
            margin: auto;
            max-width: 90%;
            font-size: var(--joy-font-size-primary-400);
        }
    }
</style>
